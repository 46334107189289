@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.fr-view,
div.fr-view,
.fr-box.fr-basic .fr-element.fr-view {
  color: $font-color;
  font-size: 1.066rem;
  font-family: $typography-font-family;

  a {
    color: $primary;
    text-decoration: underline;
  }

  hr {
    height: 2px;
    border: none;
    background-color: $secondary;
  }

  blockquote {
    padding-left: 15px;
    color: darken($primary, 10);
    border-left: solid 2px $primary;
  }
}

// Fix Placeholder Imposition.

.fr-wrapper.show-placeholder {

  .fr-element.fr-view {
    z-index: 2;
  }
}

// Override Quasar Style.

.fr-wrapper {

  [aria-disabled="false"] {
    cursor: inherit;
  }
}
