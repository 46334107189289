@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

// Block Spacing.

$bs-sm-t: map-get($space-lg, x);
$bs-sm-r: map-get($space-lg, x);
$bs-sm-b: map-get($space-gg, x);
$bs-sm-l: map-get($space-lg, x);

$bs-xs-t: map-get($space-mm, x);
$bs-xs-r: map-get($space-mm, x);
$bs-xs-b: map-get($space-md, x);
$bs-xs-l: map-get($space-mm, x);

// Block Spacing (margin).
// .bs-m{direction}

.bs-ma {
  margin: $bs-sm-t $bs-sm-r $bs-sm-b $bs-sm-l;
}

.bs-mx {
  margin-left: $bs-sm-l;
  margin-right: $bs-sm-r;
}

.bs-my {
  margin-top: $bs-sm-t;
  margin-bottom: $bs-sm-b;
}

.bs-mt {
  margin-top: $bs-sm-t;
}

.bs-mr {
  margin-right: $bs-sm-r;
}

.bs-mb {
  margin-bottom: $bs-sm-b;
}

.bs-ml {
  margin-left: $bs-sm-l;
}

@media (max-width: $breakpoint-xs-max) {

  .bs-ma {
    margin: $bs-xs-t $bs-xs-r $bs-xs-b $bs-xs-l;
  }

  .bs-mx {
    margin-left: $bs-xs-l;
    margin-right: $bs-xs-r;
  }

  .bs-my {
    margin-top: $bs-xs-t;
    margin-bottom: $bs-xs-b;
  }

  .bs-mt {
    margin-top: $bs-xs-t;
  }

  .bs-mr {
    margin-right: $bs-xs-r;
  }

  .bs-mb {
    margin-bottom: $bs-xs-b;
  }

  .bs-ml {
    margin-left: $bs-xs-l;
  }
}

// Block Spacing (padding).
// .bs-p{direction}

.bs-pa {
  padding: $bs-sm-t $bs-sm-r $bs-sm-b $bs-sm-l;
}

.bs-px {
  padding-left: $bs-sm-l;
  padding-right: $bs-sm-r;
}

.bs-py {
  padding-top: $bs-sm-t;
  padding-bottom: $bs-sm-b;
}

.bs-pt {
  padding-top: $bs-sm-t;
}

.bs-pr {
  padding-right: $bs-sm-r;
}

.bs-pb {
  padding-bottom: $bs-sm-b;
}

.bs-pl {
  padding-left: $bs-sm-l;
}

@media (max-width: $breakpoint-xs-max) {

  .bs-pa {
    padding: $bs-xs-t $bs-xs-r $bs-xs-b $bs-xs-l;
  }

  .bs-px {
    padding-left: $bs-xs-l;
    padding-right: $bs-xs-r;
  }

  .bs-py {
    padding-top: $bs-xs-t;
    padding-bottom: $bs-xs-b;
  }

  .bs-pt {
    padding-top: $bs-xs-t;
  }

  .bs-pr {
    padding-right: $bs-xs-r;
  }

  .bs-pb {
    padding-bottom: $bs-xs-b;
  }

  .bs-pl {
    padding-left: $bs-xs-l;
  }
}

// Symmetric Block Spacing.

$sbs-sm-y: map-get($space-lg, x);
$sbs-sm-x: map-get($space-lg, x);

$sbs-xs-y: map-get($space-mm, x);
$sbs-xs-x: map-get($space-mm, x);

// Symmetric Block Spacing (margin).
// .sbs-m{direction}

.sbs-ma {
  margin: $sbs-sm-y $sbs-sm-x;
}

.sbs-mx {
  margin-left: $sbs-sm-x;
  margin-right: $sbs-sm-x;
}

.sbs-my {
  margin-top: $sbs-sm-y;
  margin-bottom: $sbs-sm-y;
}

.sbs-mt {
  margin-top: $sbs-sm-y;
}

.sbs-mr {
  margin-right: $sbs-sm-x;
}

.sbs-mb {
  margin-bottom: $sbs-sm-y;
}

.sbs-ml {
  margin-left: $sbs-sm-x;
}

@media (max-width: $breakpoint-xs-max) {

  .sbs-ma {
    margin: $sbs-xs-y $sbs-xs-x;
  }

  .sbs-mx {
    margin-left: $sbs-xs-x;
    margin-right: $sbs-xs-x;
  }

  .sbs-my {
    margin-top: $sbs-xs-y;
    margin-bottom: $sbs-xs-y;
  }

  .sbs-mt {
    margin-top: $sbs-xs-y;
  }

  .sbs-mr {
    margin-right: $sbs-xs-x;
  }

  .sbs-mb {
    margin-bottom: $sbs-xs-y;
  }

  .sbs-ml {
    margin-left: $sbs-xs-x;
  }
}

// Symmetric Block Spacing (padding).
// .sbs-p{direction}

.sbs-pa {
  padding: $sbs-sm-y $sbs-sm-x;
}

.sbs-px {
  padding-left: $sbs-sm-x;
  padding-right: $sbs-sm-x;
}

.sbs-py {
  padding-top: $sbs-sm-y;
  padding-bottom: $sbs-sm-y;
}

.sbs-pt {
  padding-top: $sbs-sm-y;
}

.sbs-pr {
  padding-right: $sbs-sm-x;
}

.sbs-pb {
  padding-bottom: $sbs-sm-y;
}

.sbs-pl {
  padding-left: $sbs-sm-x;
}

@media (max-width: $breakpoint-xs-max) {

  .sbs-pa {
    padding: $sbs-xs-y $sbs-xs-x;
  }

  .sbs-px {
    padding-left: $sbs-xs-x;
    padding-right: $sbs-xs-x;
  }

  .sbs-py {
    padding-top: $sbs-xs-y;
    padding-bottom: $sbs-xs-y;
  }

  .sbs-pt {
    padding-top: $sbs-xs-y;
  }

  .sbs-pr {
    padding-right: $sbs-xs-x;
  }

  .sbs-pb {
    padding-bottom: $sbs-xs-y;
  }

  .sbs-pl {
    padding-left: $sbs-xs-x;
  }
}

// Inner Block Spacing.

$ibs-sm-y: map-get($space-md, x);
$ibs-sm-x: map-get($space-md, x);

$ibs-xs-y: map-get($space-sm, x);
$ibs-xs-x: map-get($space-sm, x);

// Inner Block Spacing (margin).
// .ibs-m{direction}

.ibs-ma {
  margin: $ibs-sm-y $ibs-sm-x;
}

.ibs-mx {
  margin-left: $ibs-sm-x;
  margin-right: $ibs-sm-x;
}

.ibs-my {
  margin-top: $ibs-sm-y;
  margin-bottom: $ibs-sm-y;
}

.ibs-mt {
  margin-top: $ibs-sm-y;
}

.ibs-mr {
  margin-right: $ibs-sm-x;
}

.ibs-mb {
  margin-bottom: $ibs-sm-y;
}

.ibs-ml {
  margin-left: $ibs-sm-x;
}

@media (max-width: $breakpoint-xs-max) {

  .ibs-ma {
    margin: $ibs-xs-y $ibs-xs-x;
  }

  .ibs-mx {
    margin-left: $ibs-xs-x;
    margin-right: $ibs-xs-x;
  }

  .ibs-my {
    margin-top: $ibs-xs-y;
    margin-bottom: $ibs-xs-y;
  }

  .ibs-mt {
    margin-top: $ibs-xs-y;
  }

  .ibs-mr {
    margin-right: $ibs-xs-x;
  }

  .ibs-mb {
    margin-bottom: $ibs-xs-y;
  }

  .ibs-ml {
    margin-left: $ibs-xs-x;
  }
}

// Inner Block Spacing (padding).
// .ibs-p{direction}

.ibs-pa {
  padding: $ibs-sm-y $ibs-sm-x;
}

.ibs-px {
  padding-left: $ibs-sm-x;
  padding-right: $ibs-sm-x;
}

.ibs-py {
  padding-top: $ibs-sm-y;
  padding-bottom: $ibs-sm-y;
}

.ibs-pt {
  padding-top: $ibs-sm-y;
}

.ibs-pr {
  padding-right: $ibs-sm-x;
}

.ibs-pb {
  padding-bottom: $ibs-sm-y;
}

.ibs-pl {
  padding-left: $ibs-sm-x;
}

@media (max-width: $breakpoint-xs-max) {

  .ibs-pa {
    padding: $ibs-xs-y $ibs-xs-x;
  }

  .ibs-px {
    padding-left: $ibs-xs-x;
    padding-right: $ibs-xs-x;
  }

  .ibs-py {
    padding-top: $ibs-xs-y;
    padding-bottom: $ibs-xs-y;
  }

  .ibs-pt {
    padding-top: $ibs-xs-y;
  }

  .ibs-pr {
    padding-right: $ibs-xs-x;
  }

  .ibs-pb {
    padding-bottom: $ibs-xs-y;
  }

  .ibs-pl {
    padding-left: $ibs-xs-x;
  }
}
