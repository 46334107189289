// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

// Colors.

$primary:   #333539;
$secondary: #E5E7ED;
$accent:    #FFC107;

$dark: #313553;

$positive: #27CBE0;
$negative: #FF5252;
$info:     #2151F3;
$warning:  #FFC107;

$shadow-color: $primary;
$separator-color: $secondary;

$light-accent: #FFBE59;

// Scroll.

$scroll-weight: 8px;

// Spacing.

$space-base: 32px;
$space-x-base: $space-base;
$space-y-base: $space-base;

$space-none: (
  x: 0,
  y: 0,
);

$space-xs: (
  x: ($space-x-base * .25),
  y: ($space-y-base * .25),
);

$space-ss: (
  x: ($space-x-base * .35),
  y: ($space-y-base * .35),
);

$space-sm: (
  x: ($space-x-base * .5),
  y: ($space-y-base * .5),
);

$space-mm: (
  x: ($space-x-base * .63),
  y: ($space-y-base * .63),
);

$space-md: (
  x: $space-x-base,
  y: $space-y-base,
);

$space-dd: (
  x: ($space-x-base * 1.1),
  y: ($space-y-base * 1.1),
);

$space-lg: (
  x: ($space-x-base * 1.5),
  y: ($space-y-base * 1.5),
);

$space-gg: (
  x: ($space-x-base * 2.2),
  y: ($space-y-base * 2.2),
);

$space-xl: (
  x: ($space-x-base * 3),
  y: ($space-y-base * 3),
);

$spaces: (
  'none': $space-none,
  'xs': $space-xs,
  'ss': $space-ss,
  'sm': $space-sm,
  'mm': $space-mm,
  'md': $space-md,
  'dd': $space-dd,
  'lg': $space-lg,
  'gg': $space-gg,
  'xl': $space-xl,
);

// Spacer Variables.

$spacer-xs: map_get($space-xs, 'x');
$spacer-ss: map_get($space-ss, 'x');
$spacer-sm: map_get($space-sm, 'x');
$spacer-mm: map_get($space-mm, 'x');
$spacer-md: map_get($space-md, 'x');
$spacer-dd: map_get($space-dd, 'x');
$spacer-lg: map_get($space-lg, 'x');
$spacer-gg: map_get($space-gg, 'x');
$spacer-xl: map_get($space-xl, 'x');

// Typography.

$font-size: 15px;
$font-color: #2D293C;

$typography-font-family: 'Rubik', Helvetica, sans-serif;

$h1: (
  size: 1.8rem,
  line-height: 2.98rem,
  letter-spacing: -.01562em,
  weight: 300,
);

$h2: (
  size: 1.4rem,
  line-height: 2.25rem,
  letter-spacing: -.00833em,
  weight: 300,
);

$h3: (
  size: 1.3rem,
  line-height: 1.95rem,
  letter-spacing: normal,
  weight: 400,
);

$h4: (
  size: 1.2rem,
  line-height: 1.8rem,
  letter-spacing: .00735em,
  weight: 400,
);

$h5: (
  size: 1.1rem,
  line-height: 1.45rem,
  letter-spacing: normal,
  weight: 400,
);

$h6: (
  size: 1rem,
  line-height: 1.3rem,
  letter-spacing: .0125em,
  weight: 500,
);


$h1-xs: (
  size: 1.7rem,
  line-height: 2rem,
  letter-spacing: -.01562em,
  weight: 400,
);

$h2-xs: (
  size: 1.5rem,
  line-height: 1.9rem,
  letter-spacing: -.00833em,
  weight: 400,
);

$h3-xs: (
  size: 1.4rem,
  line-height: 1.85rem,
  letter-spacing: normal,
  weight: 400,
);

$h4-xs: (
  size: 1.3rem,
  line-height: 1.75rem,
  letter-spacing: .00735em,
  weight: 400,
);

$h5-xs: (
  size: 1.1rem,
  line-height: 1.4rem,
  letter-spacing: normal,
  weight: 400,
);

$h6-xs: (
  size: 1rem,
  line-height: 1.25rem,
  letter-spacing: .0125em,
  weight: 500,
);

$subtitle1: (
  size: 1.2rem,
  line-height: 1.75rem,
  letter-spacing: .00937em,
  weight: 400,
);

$subtitle2: (
  size: 1rem,
  line-height: 1.375rem,
  letter-spacing: .00714em,
  weight: 500,
);

$body1: (
  size: 1rem,
  line-height: 1.5rem,
  letter-spacing: .03125em,
  weight: 400,
);

$body2: (
  size: .9rem,
  line-height: 1.25rem,
  letter-spacing: .01786em,
  weight: 400,
);

$overline: (
  size: .81rem,
  line-height: 2rem,
  letter-spacing: .16667em,
  weight: 500,
);

$caption: (
  size: .81rem,
  line-height: 1.25rem,
  letter-spacing: .03333em,
  weight: 400,
);

// Alpha pattern.

$alpha-pattern-color: #e2e2e2;
$alpha-pattern-size: 32px 32px;
$alpha-pattern-image: url('data:image/svg+xml,\
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".9" >\
    <rect x="200" width="200" fill="white" height="200" />\
    <rect y="200" width="200" fill="white" height="200" />\
  </svg>'
);

// Page Toolbar.

$page-toolbar-min-height: 50px;
$page-toolbar-max-height: 58px;

// Mixins.

@import "quasar.mixins";
