@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

$plyr-color: lighten(saturate($primary, 4), 10);

#q-app {

  .plyr--full-ui input[type=range] {
    color: $plyr-color;
  }

  .plyr__control--overlaid {
    background: transparentize($plyr-color, .2);
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded=true] {
    background: $plyr-color;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px transparentize($plyr-color, .5);
  }

  .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
    background: $plyr-color;
  }
}
