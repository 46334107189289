@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

// Body.

.q-body--force-scrollbar {
  overflow-y: auto;
}

// Typography.
// .text-h{1-6}

h1, .text-h1 {
  margin-top: $space-base * .7;
  margin-bottom: $space-base * 1;
}

h2, .text-h2 {
  margin-top: $space-base * .6;
  margin-bottom: $space-base * .9;
}

h3, .text-h3 {
  margin-top: $space-base * .5;
  margin-bottom: $space-base * .8;
}

h4, .text-h4 {
  margin-top: $space-base * .5;
  margin-bottom: $space-base * .7;
}

h5, .text-h5 {
  margin-top: $space-base * .5;
  margin-bottom: $space-base * .7;
}

h6, .text-h6 {
  margin-top: $space-base * .5;
  margin-bottom: $space-base * .7;
}

@media (max-width: $breakpoint-xs-max) {

  h1, .text-h1 {
    margin-top: $space-base * .3;
    margin-bottom: $space-base * .3;
    font-size: map-get($h1-xs, size);
    font-weight: map-get($h1-xs, weight);
    line-height: map-get($h1-xs, line-height);
  }

  h2, .text-h2 {
    margin-top: $space-base * .3;
    margin-bottom: $space-base * .3;
    font-size: map-get($h2-xs, size);
    font-weight: map-get($h2-xs, weight);
    line-height: map-get($h2-xs, line-height);
  }

  h3, .text-h3 {
    margin-top: $space-base * .3;
    margin-bottom: $space-base * .3;
    font-size: map-get($h3-xs, size);
    font-weight: map-get($h3-xs, weight);
    line-height: map-get($h3-xs, line-height);
  }

  h4, .text-h4 {
    margin-top: $space-base * .2;
    margin-bottom: $space-base * .2;
    font-size: map-get($h4-xs, size);
    font-weight: map-get($h4-xs, weight);
    line-height: map-get($h4-xs, line-height);
  }

  h5, .text-h5 {
    margin-top: $space-base * .2;
    margin-bottom: $space-base * .2;
    font-size: map-get($h5-xs, size);
    font-weight: map-get($h5-xs, weight);
    line-height: map-get($h5-xs, line-height);
  }

  h6, .text-h6 {
    margin-top: $space-base * .2;
    margin-bottom: $space-base * .2;
    font-size: map-get($h6-xs, size);
    font-weight: map-get($h6-xs, weight);
    line-height: map-get($h6-xs, line-height);
  }
}

h1, .text-h1,
h2, .text-h2,
h3, .text-h3,
h4, .text-h4,
h5, .text-h5,
h6, .text-h6 {

  &:first-child {
    margin-top: 0;
  }
}

// Shadows.
// .hover-shadow-{0-24}

@for $i from 0 to 24 {

  .hover-shadow-#{$i}:hover {
    box-shadow: nth($shadows, $i +1) !important;
    transition: $shadow-transition;
  }
}

// Scale.
// .scale-{0-10}
// .hover-scale-{0-10}

@for $i from 0 to 10 {

  .scale-#{$i} {
    transform: scale(1 + ($i * .02));
  }

  .hover-scale-#{$i}:hover {
    transform: scale(1 + ($i * .02)) !important;
    transition: transform .28s cubic-bezier(.4, 0, .2, 1);
  }
}

// DGrid.

.d-grid {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
}

// Grid.
// .grid-{0-20}

@for $i from 0 to 20 {

  $width: (50 + (50 * $i));

  .grid-#{$i} {
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(#{$width}px, 1fr));
  }

  .grid-#{$i} > * {
    min-width: 0;
  }

  @media (max-width: $breakpoint-xs-max) {

    .grid-#{$i} {
      grid-template-columns: 1fr;
    }
  }
}

// Grid Gap.
// .grid-gap-{0-5}

.grid-gap-0 {
  grid-gap: 0;
}

.grid-gap-1 {
  grid-gap: $space-base * .2;
}

.grid-gap-2 {
  grid-gap: $space-base * .6;
}

.grid-gap-3 {
  grid-gap: $space-base * 1;
}

.grid-gap-4 {
  grid-gap: $space-base * 2;
}

.grid-gap-5 {
  grid-gap: $space-base * 4;
}

// Grid Row Gap.
// .grid-row-gap-{0-5}

.grid-row-gap-0 {
  grid-row-gap: 0;
}

.grid-row-gap-1 {
  grid-row-gap: $space-base * .2;
}

.grid-row-gap-2 {
  grid-row-gap: $space-base * .6;
}

.grid-row-gap-3 {
  grid-row-gap: $space-base * 1;
}

.grid-row-gap-4 {
  grid-row-gap: $space-base * 2;
}

.grid-row-gap-5 {
  grid-row-gap: $space-base * 4;
}

// Grid Column Gap.
// .grid-column-gap-{0-5}

.grid-column-gap-0 {
  grid-column-gap: 0;
}

.grid-column-gap-1 {
  grid-column-gap: $space-base * .2;
}

.grid-column-gap-2 {
  grid-column-gap: $space-base * .6;
}

.grid-column-gap-3 {
  grid-column-gap: $space-base * 1;
}

.grid-column-gap-4 {
  grid-column-gap: $space-base * 2;
}

.grid-column-gap-5 {
  grid-column-gap: $space-base * 4;
}

// Spacing.
// .q-{space}{direction}-{value}

@each $space, $value in $spaces {

  .q-pa-#{$space} {
    padding: map-get($value, y) map-get($value, x);
  }

  .q-pl-#{$space} {
    padding-left: map-get($value, x);
  }

  .q-pr-#{$space} {
    padding-right: map-get($value, x);
  }

  .q-pt-#{$space} {
    padding-top: map-get($value, y);
  }

  .q-pb-#{$space} {
    padding-bottom: map-get($value, y);
  }

  .q-px-#{$space} {
    padding-left: map-get($value, x);
    padding-right: map-get($value, x);
  }

  .q-py-#{$space} {
    padding-top: map-get($value, y);
    padding-bottom: map-get($value, y);
  }

  .q-ma-#{$space} {
    margin: map-get($value, y) map-get($value, x);
  }

  .q-ml-#{$space} {
    margin-left: map-get($value, x);
  }

  .q-mr-#{$space} {
    margin-right: map-get($value, x);
  }

  .q-mt-#{$space} {
    margin-top: map-get($value, y);
  }

  .q-mb-#{$space} {
    margin-bottom:map-get($value, y);
  }

  .q-mx-#{$space} {
    margin-left: map-get($value, x);
    margin-right: map-get($value, x);
  }

  .q-my-#{$space} {
    margin-top: map-get($value, y);
    margin-bottom: map-get($value, y);
  }
}

// QField.

.q-field {
  font-size: 16px;
}

.q-field__bottom {
  font-size: 14px;
}

.q-field--filled {

  .q-field__label {
    top: 19px;
    font-size: 16px;
  }

  &.q-field--float .q-field__label {
    transform: translateY(-55%) scale(0.75) !important;
  }

  &.q-field--focused .q-field__control:before {
    background-color: rgba(0, 0, 0, .04);
  }

  .q-field__bottom {
    padding-top: 12px;
  }
}

.q-field--standard {
  padding-bottom: 20px;

  & {
    margin-top: 3px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  .q-field__label {
    top: 23px !important;
  }

  &.q-field--float .q-field__label {
    transform: translateY(-86%) scale(0.75) !important;
  }

  .q-field__append .q-icon {
    margin-top: 13px;
  }

  .q-field__append .lni-close {
    font-size: .8em;
  }

  .q-field__bottom {
    font-size: 13px;
    padding-top: 7px;
  }

  &.q-field--readonly .q-field__control:before {
    border-bottom-style: solid;
  }

}

.q-field--readonly.q-field--labeled  {

  .q-field__input,
  .q-field__native {
    cursor: text;
  }
}

.q-textarea.q-field--labeled {

  .q-field__control-container {
    padding-top: 43px;
    padding-bottom: 16px;
  }

  &.q-field--filled .q-field__control {
    padding: 0 18px;
  }

  &.q-field--filled.q-field--float .q-field__label {
    transform: translateY(-24%) scale(0.75) !important;
  }

  &.q-textarea--autogrow .q-field__native {
    min-height: 50px;
  }
}

.q-field--primary.q-field--filled .q-field__control {
  color: white;
  overflow: hidden;
  border-radius: 4px;
  background-color: $primary;

  .q-field__native {
    color: white;
  }

  .q-field__label {
    color: inherit;
  }
}

.q-field--white.q-field--filled .q-field__control {
  overflow: hidden;
  border-radius: 4px;
  color: $font-color;
  background-color: white;

  .q-field__native {
    color: $font-color;
  }

  .q-field__label {
    color: inherit;
  }
}

.q-field__slider {
  padding-bottom: 0;
  margin-top: 0 !important;

  .q-field__control:after,
  .q-field__control:before {
    display: none;
  }

  .q-field--standard .q-field__label {
    top: 11px !important;
  }

  &.q-field--float .q-field__label {
    transform: translateY(-50%) scale(0.75) !important;
  }

  .q-slider {
    margin-left: 6px;
    margin-right: 6px;
  }

  .q-slider__track-container {
    left: -6px;
    right: -6px;
    width: auto;
  }

  .q-field__bottom {
    padding-top: 0;
  }

  .q-field__bottom--animated {
    transform: translateY(40%);
  }
}

// QCard.

.q-card--bordered {
  border: 1px solid transparentize($primary, .7);
}

// QNotification.

.q-notification {
  font-size: 15px;
  box-shadow: none;
  transition: transform .5s, opacity .5s;

  &__message {
    padding: $space-base * .5;
  }
}

// QPopupEdit.

.q-popup-edit.q-menu {
  max-height: 90vh !important;
}

// QTooltip.

.q-tooltip {
  font-size: .8rem;
}

// QBtn.

.q-btn {

  &__content .q-icon.lni-chevron-left {
    position: relative;
    left: -2px;
  }
}

//  QCheckbox

.q-checkbox {
  margin-bottom: 10px;

  &.block {
    width: 100%;
    display: inline-flex !important;
  }

  &__label {
    font-size: 1.05rem;
  }

  &:not(:first-child) {
    margin-top: 13px;
  }

  &--push-left {
    margin-left: -10px;
  }
}

// QPagination.

.q-pagination {

  .q-btn {
    min-height: 2.3em;
    font-weight: normal;
    background-color: white;
    padding: 0 10px !important;
    min-width: 2.4em !important;
    font-size: 1.1rem !important;
    margin: 0 map-get($space-xs, x);
  }

  .q-btn--actionable {
    box-shadow: none;
  }

  @media (max-width: $breakpoint-xs-max) {

    .q-btn {
      margin: 5px;
    }
  }
}

// QDate.

.q-date {

  .q-btn-item .q-btn__content {
    margin-top: 1px;
  }

  .q-btn-item.q-date__today {
    box-shadow: none;
    border: 2px solid $secondary;
  }

  .q-btn-item.q-date__today .q-btn__content {
    margin-top: -1px;
  }

  .q-btn-item.bg-primary.text-white {
    color: $primary !important;
    background-color: $accent !important;
  }
}

// QTime.

.q-time {

  &__container-child {
    background-color: $secondary;
  }
}

// QBanner.

.q-banner {
  padding: $space-base * .5 $space-base * .8;

  &__content {
    font-size: $font-size;
  }
}

// QDialog.

.q-dialog {

  &__inner--fullwidth,
  &__inner--fullheight {
    padding: 0;

    & > div {
      border-radius: 0;
    }
  }
}

// QImg.

.q-img.q-img_contain {
  background-size: $alpha-pattern-size;
  background-image: $alpha-pattern-image;
  background-color: $alpha-pattern-color;

  .q-img__image {
    border: 20px solid transparent;
    background-size: contain !important;
  }
}
