@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

// Global.

html,
body {
  color: $font-color;
  font-size: $font-size;
}

input,
textarea {
  font-size: 1.066rem;
}

a {
  color: $primary;
  text-decoration: none;
}

.full-basis {
  flex-basis: 100%;
}

// Text.

.text-small {
  font-size: .95rem;
  line-height: 1.2rem;
}

.text-default {
  color: $font-color;
}

// Color.

.text-light-accent {
  color: $light-accent;
}

.bg-light-accent {
  background-color: $light-accent;
}

// Flex.

.no-shrink {
  flex-shrink: 0 !important;
}

// Helpers.

.no-shrink {
  flex-shrink: 0 !important;
}

.no-min-width {
  min-width: 0 !important;
}

.no-visibility {
  visibility: hidden;
}

.max-width-100 {
  max-width: 100%;
}

// Alpha Pattern.

.alpha-pattern {
  background-size: $alpha-pattern-size;
  background-image: $alpha-pattern-image;
  background-color: $alpha-pattern-color;
}

// Page Toolbar.

.page-toolbar {
  min-height: $page-toolbar-max-height;
}

@media (max-width: $breakpoint-xs-max) {

  .page-toolbar {
    min-height: $page-toolbar-min-height;
  }
}

// Art Btn.

.art-btn.q-btn,
.art-btn_left.q-btn,
.art-btn_right.q-btn {

  .q-icon {
    font-size: 3.7em;
    margin-top: -.08em;
  }
}

.art-btn_left.q-btn {
  margin-left: 1.48em;

  .q-icon {
    margin-right: .4em;
  }
}

.art-btn_right.q-btn {
  margin-right: 1.48em;

  .q-icon {
    margin-left: .4em;
  }
}

// Art Pagination.

.art-pagination.q-pagination {

  .q-btn {
    border-radius: 50%;
    padding: 0 16px !important;
    min-width: 2.7em !important;
    min-height: 2.7em !important;
  }

  .q-btn.text-white {
    color: $primary !important;
  }

  .q-btn.bg-primary {
    background-color: white !important;
  }

  .q-btn.bg-primary:before {
    top: 50%;
    left: 50%;
    content: '';
    width: 1.7em;
    height: 1.7em;
    margin-top: -.8em;
    position: absolute;
    border-radius: 50%;
    margin-left: -.7em;
    background-color: $accent;
  }

  .q-btn .q-btn__content {
    position: relative;
  }
}

// Block Btn.

.block-btn.q-btn {
  font-weight: 400;
  font-size: 1.1rem;
  padding: ($space-base * .08) ($space-base * .6);
}

// Scroll.

.scroll_grey {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: $scroll-weight;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-5;
  }
}
